import firebase from 'firebase/app';
export default class FirebaseFunctions {
  public static async createGroup(groupName: string, worldId: string) {
    const createGroup = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('createGroup');
    const result = await createGroup({ groupName, worldId });

    return result.data;
  }
}
