


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { GroupData } from '@/store/types';
import FirebaseFunctions from '@/classes/firebaseFunctions';

@Component({
  components: {},
})
export default class Group extends Vue {
  public name: string = '';
  public isLoading = false;

  private groupCreationRunning = false;
  private error: string | null = null;
  private loadingWorld = false;
  private worldHint: string | null = null;
  private newGroupDialog = false;

  private groupName: string = '';
  private worldId: string = '';

  public created() {
    if (this.currentGroupData === null && this.currentGroup !== null) {
      this.isLoading = true;
      this.$store
        .dispatch('loadGroupData', this.currentGroup)
        .finally(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error;
        });
    }
  }

  public createGroup() {
    const groupName = 'Testgruppe';
    const world = 'pathfinder-2e';
    this.groupCreationRunning = true;
    FirebaseFunctions.createGroup(groupName, world)
      .then((result) => {
        this.$store.commit('addMessage', 'Gruppe erstellt');
        this.$store.dispatch('loadUserData');
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.groupCreationRunning = false;
      });
  }

  public switchCurrentGroup(groupId: string) {
    this.$store.dispatch('switchCurrentGroup', groupId);
  }

  get currentGroup(): string | null {
    return this.$store.getters.currentGroup;
  }

  get currentGroupData(): GroupData | null {
    return this.$store.getters.currentGroupData;
  }

  get groups(): string[] {
    return this.$store.getters.groups;
  }

  get invitations(): string[] {
    return [];
  }

  get members() {
    if (this.currentGroupData === null) {
      return [];
    } else {
      return this.currentGroupData.members;
    }
  }
}
